import { useEffect, useState } from "react";
import io from "socket.io-client";

const server = document.location.host.includes("localhost")
  ? "http://localhost:3001"
  : "";
const socket = io(server);

let socketTimeout = setTimeout(() => {});
let locationTimeout = setTimeout(() => {});
let currentSession: string | null = null;
let currentCode: string = "";

export const useVirtualKeypad = (site: string, pad: string) => {
  // Request access to geolocation
  const [geolocation, setGeolocation] = useState<
    "ok" | number | "refused" | "accuracy" | null
  >(null);

  const getGeolocation = (count = 0) => {
    // Check if the user is within 100 meters of the pad
    // Expected location from url querystring geo
    const expectedLocation = new URLSearchParams(window.location.search).get(
      "geo"
    ); // As string "1,2"

    if (!expectedLocation) {
      setTimeout(() => {
        setGeolocation("ok");
      }, 1000);
      return;
    }

    navigator.geolocation.getCurrentPosition((position) => {
      locationTimeout && clearTimeout(locationTimeout);
      // If refused, the position will be null
      if (!position) {
        setGeolocation("refused");
      } else {
        if (position.coords.accuracy > 200 && count < 3) {
          locationTimeout && clearTimeout(locationTimeout);
          setTimeout(() => {
            getGeolocation(count + 1);
          }, 5000);
          return;
        }

        const [expectedLat, expectedLon] = expectedLocation.split(",");
        const distance = getDistanceFromLatLonInKm(
          position.coords.latitude,
          position.coords.longitude,
          parseFloat(expectedLat),
          parseFloat(expectedLon)
        );
        if (distance < 0.2) {
          setGeolocation("ok");
        } else if (position.coords.accuracy > 200) {
          setGeolocation("accuracy");
        } else {
          setGeolocation(distance);
        }
      }
    });
    locationTimeout && clearTimeout(locationTimeout);
    locationTimeout = setTimeout(() => {
      setGeolocation("refused");
    }, 5000);
  };

  useEffect(() => {
    getGeolocation();
  }, []);

  const [state, setState] = useState<"ok" | "ko" | "loading" | null>(null);
  const [savedCode, setSavedCode] = useState<string | null>(
    localStorage.getItem(`saved:${site}:${pad}`)
  );

  // Need access to socket for keypad result
  useEffect(() => {
    socket.on("state", (result: { session: string; valid: boolean }) => {
      // if session === session then proceed
      if (result?.session === currentSession) {
        socketTimeout && clearTimeout(socketTimeout);
        if (result.valid) {
          localStorage.setItem(`saved:${site}:${pad}`, currentCode);
        }
        setState(result.valid ? "ok" : "ko");
      }
    });
    return () => {
      socket.off("state");
    };
  }, []);

  // Type code
  const entersCode = async (code: string) => {
    currentCode = code;
    setState("loading");
    // POST to /api/open with params { site: "site id", pad: "pad id", code: "code" }
    try {
      const session = site + ":" + pad + ":" + code;
      currentSession = session;
      socket.emit("join", "session:" + session);
      await fetch(server + "/api/open", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          site,
          pad,
          code,
        }),
      });

      socketTimeout = setTimeout(() => {
        setState("ko");
      }, 5000);
    } catch (err) {
      // Error bad code
      setState("ko");
    }
  };

  return {
    geolocation,
    getGeolocation,
    entersCode,
    savedCode,
    resetSavedCode: () => {
      setSavedCode(null);
      localStorage.removeItem(`saved:${site}:${pad}`);
    },
    setState,
    state,
  };
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}
