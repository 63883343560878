import {
  ArrowRightIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/16/solid";
import { Geoloc } from "../components/geoloc";
import { Layout } from "../components/layout";
import { Loader } from "../components/loader";
import { Pad } from "../components/pad";
import { useVirtualKeypad } from "../hooks/use-virtual-keypad";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";

// http://localhost:3000/?site=default&pad=123&geo=1,2

function App() {
  const {
    entersCode,
    geolocation,
    state,
    setState,
    savedCode,
    resetSavedCode,
  } = useVirtualKeypad(
    new URLSearchParams(window.location.search).get("site") || "1",
    new URLSearchParams(window.location.search).get("pad") || "1"
  );

  const showGeoloc =
    !geolocation ||
    geolocation === "refused" ||
    geolocation === "accuracy" ||
    typeof geolocation === "number";

  useEffect(() => {
    if (state === "ko") {
      setTimeout(() => {
        setState(null);
      }, 3000);
    }
  });

  return (
    <div className="w-screen h-screen relative overflow-hidden dark:bg-primary-500">
      <Layout title="Vérification de votre position..." show={showGeoloc}>
        <Geoloc geolocation={geolocation} />
      </Layout>
      <Layout
        title={
          savedCode
            ? "Utiliser un code enregistré"
            : "Entrez votre code d'accès pour continuer"
        }
        show={!showGeoloc && state === null}
      >
        {!!savedCode && (
          <div className="text-center">
            <div
              className="flex w-full items-center justify-center space-x-2"
              onClick={() => entersCode(savedCode)}
            >
              <div className="bg-slate-200 dark:bg-primary-400 px-6 rounded-full h-14 flex items-center justify-center text-3xl">
                <span className="-mb-2.5">* * * * * *</span>
              </div>
              <div
                className={twMerge(
                  "rounded-full w-14 h-14 flex items-center justify-center text-2xl font-semibold transition-all active:scale-95 active:transition-none",
                  "bg-green-500 text-white"
                )}
              >
                <ArrowRightIcon className="w-6 h-6" />
              </div>
            </div>
            <br />
            <a href="#" onClick={() => resetSavedCode()}>
              Utiliser un autre code
            </a>
          </div>
        )}
        {!savedCode && (
          <Pad
            onEnter={(code) => entersCode(code)}
            disabled={!(!showGeoloc && state === null)}
          />
        )}
      </Layout>
      <Layout
        title={
          state === "loading"
            ? ""
            : state === "ok"
            ? "Accès autorisé"
            : "Accès refusé"
        }
        show={!showGeoloc && state !== null}
      >
        <div className="text-center flex items-center flex-col space-between h-64">
          <div className="grow" />
          {state === "loading" && <Loader />}
          {state === "ok" && (
            <LockOpenIcon className="w-10 h-10 text-green-500" />
          )}
          {state === "ko" && (
            <LockClosedIcon className="w-10 h-10 text-red-500 shake-animation" />
          )}
          <div className="grow" />
        </div>
      </Layout>
    </div>
  );
}

export default App;
