import { ArrowRightIcon, BackspaceIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export const Pad = ({
  onEnter,
  disabled,
}: {
  onEnter: (code: string) => void;
  disabled?: boolean;
}) => {
  const [code, setCode] = useState("");

  const lineClass =
    "w-full flex flex-row h-1/3 justify-center items-center h-16 xs:h-20";
  const rowClass =
    "w-1/3 h-1/3 flex items-center justify-center h-16 w-16 xs:w-20";
  const btnClass =
    "rounded-full w-14 h-14 flex items-center justify-center text-2xl font-semibold transition-all active:scale-95 active:transition-none";

  useEffect(() => {
    // Listen for keypad events
    const listener = (e: KeyboardEvent) => {
      if (!disabled) {
        if (e.key === "Enter" && code) {
          onEnter(code);
          setTimeout(() => setCode(""), 1000);
        }
        if (e.key === "Backspace") setCode(code.slice(0, -1));
        if (/[0-9]/.test(e.key)) setCode(code + e.key);
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [code, disabled]);

  return (
    <>
      <div className="h-24 flex flex-row items-center justify-center max-w-64 mx-auto overflow-visible">
        {code.split("").map((_a, i) => (
          <div
            key={i}
            className="rounded-full w-3 h-3 bg-primary-500 dark:bg-white code-bounce-from-right-animation shrink-0"
          />
        ))}
      </div>
      <div className="max-w-64 mx-auto shrink-0 h-max">
        {[7, 4, 1].map((i) => (
          <div key={i} className={lineClass}>
            {[0, 1, 2].map((j) => (
              <div
                className={rowClass}
                key={j}
                onClick={() => setCode(code + "" + (i + j))}
              >
                <div
                  className={twMerge(
                    btnClass,
                    "bg-primary-500 text-white dark:bg-white dark:text-primary-500"
                  )}
                >
                  {i + j}
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={lineClass}>
          <div className={rowClass} onClick={() => setCode(code.slice(0, -1))}>
            <div
              className={twMerge(
                btnClass,
                "bg-red-500 text-white",
                !code && "opacity-50"
              )}
            >
              <BackspaceIcon className="w-6 h-6 -ml-0.5" />
            </div>
          </div>
          <div className={rowClass}>
            <div
              className={twMerge(
                btnClass,
                "bg-primary-500 text-white dark:bg-white dark:text-primary-500"
              )}
              onClick={() => setCode(code + "0")}
            >
              0
            </div>
          </div>
          <div className={rowClass}>
            <div
              className={twMerge(
                btnClass,
                "bg-green-500 text-white",
                !code && "opacity-50"
              )}
              onClick={() => {
                code && onEnter(code);
                setTimeout(() => setCode(""), 1000);
              }}
            >
              <ArrowRightIcon className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
