import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export const Layout = ({
  children,
  title,
  show,
}: {
  children: React.ReactNode;
  title: string;
  show?: boolean;
}) => {
  const [wasVisible, setWasVisible] = useState(show);
  useEffect(() => {
    if (show && !wasVisible) setWasVisible(true);
    if (!show && wasVisible) setTimeout(() => setWasVisible(false), 500);
  }, [show]);

  return (
    <>
      <div
        className={twMerge(
          "m-auto absolute w-full top-0 bottom-0 left-0 right-0 h-full flex flex-col overflow-hidden  text-primary-500 dark:text-white select-none transition-all duration-300",
          show
            ? "opacity-100 translate-x-0"
            : wasVisible
            ? "opacity-0 pointer-events-none -translate-x-full scale-50"
            : "opacity-0 pointer-events-none translate-x-full scale-50"
        )}
      >
        <div className="grow" />

        <div className="h-24 flex flex-row items-center justify-center max-w-64 mx-auto text-center font-medium text-xl text-primary-500 dark:text-white">
          {title}
        </div>

        {children}

        <div className="grow" />
        <div className="h-24" />
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-24 flex flex-row items-center justify-center max-w-64 mx-auto">
        <img src="/logoDixly_b.svg" className="h-6 dark:hidden block" />
        <img src="/logoDixly_w.svg" className="h-6 dark:block hidden" />
      </div>
    </>
  );
};
