import { Loader } from "./loader";

export const Geoloc = ({
  geolocation,
}: {
  geolocation: "ok" | number | "refused" | "accuracy" | null;
}) => {
  return (
    <div className="text-center flex items-center flex-col space-between h-64">
      <div className="grow"></div>
      {!geolocation && <Loader />}
      {geolocation === "refused" && (
        <div>
          Nous devons accéder à votre position pour continuer.
          <br />
          <br />{" "}
          <span className="text-sm opacity-50">
            Vérifiez les paramètres de votre appareil.
          </span>
          <br />
          <br />
          <a href="">Réessayer</a>
        </div>
      )}
      {geolocation === "accuracy" && (
        <div>
          La précision de votre appareil est insuffisante.
          <br />
          <br />
          <a href="">Réessayer</a>
        </div>
      )}
      {typeof geolocation === "number" && geolocation > 0 && (
        <div>
          Vous êtes trop loin, vous ne pouvez pas continuer.
          <br />
          <br />{" "}
          <span className="text-sm opacity-50">
            {Math.floor(geolocation * 100) / 100} km
          </span>
        </div>
      )}
      <div className="grow"></div>
    </div>
  );
};
